/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-procurant-primary: mat.define-palette(mat.$indigo-palette);
$my-procurant-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$my-procurant-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$my-procurant-theme: mat.define-light-theme($my-procurant-primary, $my-procurant-accent, $my-procurant-warn);

/* kendo */
//@import "~@progress/kendo-theme-default/dist/all";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-procurant-theme);

@font-face {
  font-family: "sf_ui_displaysemibold";
  src: url("../src/assets/fonts/sf-ui-display-semibold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_displayregular";
  src: url("../src/assets/fonts/sf-ui-display-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_displaymedium";
  src: url("../src/assets/fonts/sf-ui-display-medium-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sf_ui_displaybold";
  src: url("../src/assets/fonts/sf-ui-display-bold-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/sf-ui-display-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*Icons for site*/
@font-face {
  font-family: "icomoon";
  src: url("../src/assets/images/image-fonts/icomoon.eot?2mi4ol");
  src: url("../src/assets/images/image-fonts/icomoon.eot?2mi4ol#iefix") format("embedded-opentype"),
    url("../src/assets/images/image-fonts/icomoon.ttf?2mi4ol") format("truetype"),
    url("../src/assets/images/image-fonts/icomoon.woff?2mi4ol") format("woff"),
    url("../src/assets/images/image-fonts/icomoon.svg?2mi4ol#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*Icons 2*/
@font-face {
  font-family: "icomoon2";
  src: url("../src/assets/images/image-fonts/icomoon2.eot?9nv9wg");
  src: url("../src/assets/images/image-fonts/icomoon2.eot?9nv9wg#iefix") format("embedded-opentype"),
    url("../src/assets/images/image-fonts/icomoon2.ttf?9nv9wg") format("truetype"),
    url("../src/assets/images/image-fonts/icomoon2.woff?9nv9wg") format("woff"),
    url("../src/assets/images/image-fonts/icomoon2.svg?9nv9wg#icomoon2") format("svg");
  font-weight: normal;
  font-style: normal;
}

// * {
//   font-family: "sf_ui_displayregular";
// }
// *,
// *::before,
// *::after {
//   font-family: inherit;
// }

[class^="icon2-"],
[class*=" icon2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon2" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "procurant";
  src: url("assets/fonts/procurant.eot?46989818");
  src: url("assets/fonts/procurant.eot?46989818#iefix") format("embedded-opentype"),
    url("assets/fonts/procurant.woff2?46989818") format("woff2"),
    url("assets/fonts/procurant.woff?46989818") format("woff"),
    url("assets/fonts/procurant.ttf?46989818") format("truetype"),
    url("assets/fonts/procurant.svg?46989818#procurant") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon2-drag:before {
  content: "\e9114";
  color: #739d44;
}

.icon2-view-details:before {
  content: "\e9115";
  color: #739d44;
}

// .icon-claim:before {
//   content: "\e909";
//   color: #fff;
// }
.icon2-business-rules:before {
  content: "\e90b";
  color: #fff;
}

.icon2-trading-partners:before {
  content: "\e90c";
  color: #fff;
}

.icon2-business-unit:before {
  content: "\e90d";
  color: #fff;
}

// .icon2-invoice:before {
//   content: "\e90e";
//   color: #fff;
// }

.icon2-info:before {
  content: "\e90a";
  color: #739d44;
  border: 1px solid #739d44;
  border-radius: 100%;
  padding: 2px;
}

.icon2-info-danger:before {
  content: "\e90a";
  color: #e9670e;
  border: 1px solid #e9670e;
  border-radius: 100%;
  padding: 2px;
  cursor: pointer;
}

.icon-edit:before {
  content: "\e932";
  color: #739d44;
  font-weight: 600;
}

.icon-account_settings:before {
  content: "\e905";
  color: #fff;
}

.icon-analytics:before {
  content: "\e901";
  color: #fff;
}

.icon-catalog:before {
  content: "\e907";
  color: #fff;
}

.icon-dashboard:before {
  content: "\e908";
  color: #fff;
}

.icon-orders_list:before {
  content: "\e909";
  color: #fff;
}

.icon-payments:before {
  content: "\e90a";
  color: #fff;
}

.icon-analytics:before {
  content: "\e906";
  color: #fff;
}

.icon-promotions:before {
  content: "\e907";
  color: #fff;
}

.icon-task_centre:before {
  content: "\e90d";
  color: #fff;
}

.icon-demand:before {
  content: "\e90e";
  color: #fff;
}

// .icon-inventory:before {
//   content: "\e90e";
//   color: #fff;
// }
.icon-logistics:before {
  content: "\e90f";
  color: #fff;
}

.icon-source:before {
  content: "\e90c";
  color: #fff;
}

.icon-tasks:before {
  content: "\e90d";
  color: #fff;
}

// .icon-empty_star:before {
//   content: "\e90e";
//   color: #fff;
// }
.icon-filled_star:before {
  content: "\e90f";
  color: #e5c60b;
}

.icon-half_filled_star:before {
  content: "\e910";
}

.icon-tools:before {
  content: "\e911";
  color: #739d44;
}

.icon-catalog2:before {
  content: "\e912";
  color: #4a4a4a;
}

.icon-featured_suppliers:before {
  content: "\e913";
}

.icon-location:before {
  content: "\e914";
  color: #739d44;
}

.icon-right_arrow:before {
  content: "\e915";
  color: #739d44;
}

.icon-today_deal:before {
  content: "\e916";
  color: #4a4a4a;
}

.icon-trending_product:before {
  content: "\e917";
  color: #4a4a4a;
}

.icon-image_upload:before {
  content: "\e918";
  color: #739d44;
}

.icon-upload_file:before {
  content: "\e91d";
  color: #739d44;
}

.icon-upload_file_grey:before {
  content: "\e91d";
  color: #4a4a4a;
}

.icon-search:before {
  content: "\e91e";
  color: #739d44;
}

.icon-history-dm:before {
  content: "\e91f";
  color: #739d44;
}

.icon-other:before {
  content: "\e91c";
  color: #838383;
}

.icon-cancel:before {
  content: "\e902";
  color: #739d44;
  border: 1px solid #739d44;
  border-radius: 100%;
}

.icon-payments2:before {
  content: "\e921";
  color: #838383;
}

.icon-transportation:before {
  content: "\e922";
  color: #838383;
}

.icon-comment_green:before {
  content: "\e923";
  color: #739d44;
}

.icon-wishlist:before {
  content: "\e920";
  color: #739d44;
}

.icon-preview:before {
  content: "\e924";
  color: #739d44;
}

.icon-calender:before {
  content: "\e925";
  color: #739d44;
}

.icon-delete:before {
  content: "\e926";
  color: #739d44;
}

.icon-reminders:before {
  content: "\e923";
  color: #739d44;
}

.icon-chat_message:before {
  content: "\e928";
  // color: #2a3037;
}

.icon-chat:before {
  content: "\e925";
  color: #4a4a4a;
}

.icon-flag:before {
  content: "\e926";
  color: #d0021b;
}

.icon-messages:before {
  content: "\e927";
}

.icon-recent_activity:before {
  content: "\e928";
}

.icon-reminder_notification:before {
  content: "\e92d";
  color: #2a3037;
}

.icon-system_generated_message:before {
  content: "\e92a";
  color: #858585;
}

.icon-iot:before {
  content: "\e910";
  color: #fff;
}

.icon2-settings:before {
  content: "\e902";
  color: #fff;
}

.icon2-send:before {
  content: "\e904";
  color: #fff;
  font-weight: 600;
}

.icon-earth:before {
  content: "\e9ca";
  color: #fff;
}

.icon-codepen:before {
  content: "\eae8";
  color: #fff;
}

.icon-barcode:before {
  content: "\e937";
  color: #fff;
}

.icon-users:before {
  content: "\e972";
  color: #fff;
}

.icon-service:before {
  content: "\e900";
  color: #fff;
}

.icon-key:before {
  content: "\e98d";
  color: #739d44;
}

.icon-clear-filter:before {
  content: "\e91b";
  color: #739d44;
  // font-weight: 600;
}

// (RD)PMB-6554 New header and navigation menu bar for Procurant Commerc
.icon-notification1:before {
  content: "\e943";
}

// SVG font icons starts here

[class^="icons-"]:before,
[class*=" icons-"]:before {
  font-family: "procurant";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icons-reprocess:before {
  content: "\e800";
}

.icons-preview:before {
  content: "\e801";
}

.icons-outbound:before {
  content: "\e802";
}

.icons-inbound:before {
  content: "\e803";
}

.icons-alldocs:before {
  content: "\e804";
}

.icons-documentmonitor:before {
  content: "\e805";
}

.icons-download:before {
  content: "\e812";
}

html {
  margin: 0;
  padding: 0;
  height: 100%;

  body {
    margin: 0;
    padding: 0;
    font-family: "sf_ui_displayregular";
    height: 100%;
  }

  .content-container {
    .main-content {
      padding: 15px;
    }
  }
}

// SVG font icons ends here

.roundBtn {
  padding: 0 !important;
  width: 40px;
  height: 40px;
  min-width: 40px !important;
  border-radius: 50px !important;

  &:disabled {
    filter: grayscale(1);
    opacity: 0.7;
  }

  .icons-alldocs,
  .icons-preview,
  .icons-reprocess {
    color: #739d44;
  }
}

.icons-outbound,
.icons-inbound {
  color: #739d44;
}

.d-block {
  display: block !important;
}

.withGreyBg {
  background-color: #f9f9f9;
}

.mdTabsNoBg {
  mat-tab-header {
    background: none;
    margin-top: 0px;
    border: none;

    .mat-tab-label-content {
      a {
        padding: 10px 0;
      }
    }
  }

  .label-text {
    margin-left: 10px;
  }
}

.label-text {
  font-size: 16px;
  color: #4a4a4a;
  font-weight: bold;
}

.pos-r {
  position: relative;
}

.document-monitor-button {
  position: absolute;
  right: 0px;
  z-index: 2;
  top: 15px;
}

.sideBarIco.icons-documentmonitor {
  color: #fff;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

/*removing default padding and margin*/
body {
  margin: 0;
  padding: 0;
  font-family: "sf_ui_displayregular";
}

/* Side navigation */
// .sidenav {
//     > .mat-drawer-inner-container {
//       flex-direction: column;
//       box-sizing: border-box;
//       display: flex;
//       place-content: stretch space-between;
//       align-items: stretch;
//     }
//   }
// .mat-nav-list{
//     .mat-list-item{
//         height: 60px !important;
//         border-bottom: 1px solid rgba(255,255,255,.15);
//         .mat-list-item-content{
//             padding: 0 23px !important;

//             & > span{
//                 font-size: 20px;
//                 opacity: .5;
//             }
//         }
//     }
// }
.bottomList {
  .mat-list-item {
    &:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

/* Right side container */
.contentWrapper {
  padding: 65px 0 40px 66px;
}

.contentWrapper.contentWrapperColor {
  background-color: #f9f9f9;
}

.contentLoginWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.mat-drawer-container {
  background: #fff;
  overflow: inherit !important;
}

/* Table */
.table {
  width: 100%;
  border-collapse: collapse;

  td {
    border: 1px solid #e3e2e2;
    padding: 8px 10px !important;

    &.pd-0 {
      padding: 0 !important;
    }

    .tableSecondLevel {
      td {
        border: 0;

        &:last-child {
          border: 0;
        }

        &.tdBorderRight {
          border-right: 1px solid #e3e2e2 !important;
        }
      }
    }

    .fieldLabel,
    .fieldValue {
      color: #4b4b4b;
    }

    .ImgWrap {
      width: 80px;
      height: 80px;
      margin: auto;

      img {
        width: 100%;
        margin: auto;
        display: block;
      }
    }

    mat-form-field {
      width: 100%;

      .mat-form-field-underline {
        height: 0;
      }
    }

    .mat-form-field-wrapper {
      padding: 0;

      .mat-form-field-infix {
        border: 0;
        // padding:0;
      }
    }

    .mat-form-field-label-wrapper {
      padding: 0;
      top: 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    &.mat-column-expandedDetail {
      padding: 0 !important;
      overflow: hidden;
    }

    &.expandControl {
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid #9b9b9b;
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        margin-top: -4px;
        margin-right: 10px;
      }
    }

    .tdBg {
      background-color: #f9f9f9;
    }

    .tdBtn {
      padding: 0;
      margin: 0;
      width: auto;
      height: auto;
      line-height: normal;
      min-width: auto;
      float: right;
    }

    .infoBlocks .readOnlyField,
    .infoBlocks .formControl {
      padding-right: 0;
    }
  }

  .element-detail-row .infoBlocks {
    padding: 10px 10px;
  }

  .tdBg {
    background-color: #f9f9f9;
  }

  th {
    border-top: 1px solid #739d44 !important;
    padding: 8px 10px !important;
    border-bottom: 1px solid #739d44 !important;
    border-right: 1px solid #e3e2e2;
    border-left: 1px solid #e3e2e2;

    &.pd-0 {
      padding: 0 !important;
    }

    .tableSecondLevel {
      th {
        border: 0;
        border-top: 1px solid #e3e2e2;
        border-right: 1px solid #e3e2e2;
        border-bottom: 0 !important;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .thBtn {
      padding: 0;
      margin: 0;
      width: auto;
      height: auto;
      line-height: normal;
      min-width: auto;
      float: right;
    }
  }

  tr.mat-header-row,
  .mat-row {
    height: auto !important;
  }

  .updatedRow {
    td:first-child {
      border-left: 10px solid #e9670e;
    }

    .expandCta {
      span {
        &.collapsed:before {
          border-top-color: #e9670e !important;
        }

        &.expanded:before {
          border-bottom-color: #e9670e !important;
        }
      }
    }
  }

  td.fixedData {
    position: relative;
    left: 0;
    top: 0;

    >span {
      position: absolute;
      vertical-align: middle;
      top: 50%;
      left: 10px;
      margin-top: -15px;
      background: rgba(0, 0, 0, 0.04);
      width: 30px;
      height: 30px;
      border-radius: 100%;
      text-align: center;
      line-height: 30px;
    }
  }

  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #9b9b9b;
  }

  .mat-checkbox-frame {
    border-width: 0px;
  }

  .mat-checkbox-checked .mat-checkbox-inner-container,
  .mat-checkbox-indeterminate .mat-checkbox-inner-container {
    border: 1px solid #fff;
  }

  tfoot {
    background: #f9f9f9;

    td {
      padding: 20px 10px !important;
      border: 0;
    }
  }

  .mat-footer-cell {
    font-size: 18px;
    color: #2a3037;
    font-family: "sf_ui_displaysemibold";
  }
}

.element-active-row {
  .expandControl {
    position: relative;
    cursor: pointer;

    &:before {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 8px solid #9b9b9b;
      border-top: 0 !important;
    }
  }
}

.element-detail-row {
  height: auto !important;

  .infoBlocks {
    padding: 20px;
  }
}

.responsiveTable {
  overflow-x: auto;
}

.mat-paginator-container {
  justify-content: center !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 15px !important;
  margin: auto;
  width: auto !important;
  min-height: auto !important;

  .mat-paginator-icon {
    width: 22px;
  }

  .mat-paginator-page-size-select {
    margin: 6px 4px 0 4px;
    width: 50px;

    .mat-select-arrow:before {
      border-width: 6px;
    }

    .mat-select-arrow:after {
      border-width: 6px;
    }
  }
}

.mat-paginator {
  margin-top: 40px;
  margin-bottom: 40px;
}

/* .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element{
    background: #092541;
} */
.mat-sort-header-button {
  text-align: left !important;
}

.mat-sort-header-arrow {
  color: #739d44;
}

.mat-cell,
.mat-footer-cell {
  color: #2a3037;
}

.mat-header-cell {
  color: #4b4b4b;
  font-size: 14px;
  font-family: "sf_ui_displaysemibold";
}

.mat-cell,
.mat-footer-cell {
  font-size: 16px;
}

.mat-elevation-z8 {
  box-shadow: none;
}

.activeRow {
  background-color: #f9f9f9;
}

.second-footer-row {
  border-top: 20px solid #fff;
}

.third-footer-row {
  background: #fff;

  td {
    border: 0 !important;

    span {
      color: #4a4a4a;
    }
  }
}

.grandTotal {
  &.tdHighlight {
    width: auto;
    margin: 20px 171px 0 46px;
    border-radius: 6px;
    box-shadow: inset 0 0 0px 1px #9aa8b5;
    // border: solid 1px #9aa8b5 !important;
    border: 0 !important;
    background-color: #fff;
    padding: 10px;

    .total {
      span {
        font-size: 20px;
        color: #2a3037;
        font-family: "sf_ui_displaysemibold";
      }
    }
  }
}

.tdHighlight {
  border-radius: 6px;
  box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.1);
  // border: solid 1px #9aa8b5 !important;
  border: 0 !important;
  background-color: #fff;

  span {
    font-size: 24px;
  }
}

.border_B {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.tdHighlight2 {
  background-color: #f9f9f9;
  box-shadow: inset 0px 20px 0px 0px #fff;
}

.table tfoot td.tdHighlight2 {
  padding-top: 40px !important;
}

.cdk-column-blanks {
  background: #fff;
  border: 0 !important;
}

.tableFilterPanel {
  padding: 20px;
  padding-left: 0px;
  padding-right: 0px;

  .dateRange {
    .mat-form-field-infix {
      width: 155px;
    }
  }
}

.table .mat-column-select {
  // padding-left: 20px;
  // padding-right: 20px;
  width: 20px;
}

.mat-table,
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-family: "sf_ui_displayregular";
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "sf_ui_displaymedium";
}

.mat-dialog-content {

  table tr td,
  table tr th {
    &:first-child {
      border-left: 0 !important;
    }

    &:last-child {
      border-right: 0 !important;
    }
  }
}

/* Form */
//(AP)EDI-3193 Improve/Increase Speed of Document Monitoring for Internal Admin Search 
.formContainer {
  mat-form-field {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.space40 {
    mat-form-field {
      margin-right: 40px;
    }
  }

  .dependentField:last-child {
    mat-form-field {
      margin-right: 0 !important;
    }
  }

  .mat-checkbox-label {
    font-size: 12px;
    line-height: normal;
    color: #4a4a4a;
    font-family: "sf_ui_displayregular" !important;
    padding-left: 10px;
  }

  &.fullFields {
    mat-form-field {
      width: 100%;
      margin-right: 0;
    }
  }

  &.halfFields {
    mat-form-field {
      width: 50%;
      display: inline-block;
      margin-right: 0;

      .mat-form-field-wrapper {
        margin-right: 15px;
      }
    }
  }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #c7d1dc;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #739d44;
}

.mat-form-field-label {
  color: #c7d1dc;
  font-size: 18px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background: #092541;
}

.mat-sort-header-arrow {
  color: #739d44;
}

.mat-form-field-label,
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #c7d1dc;
  font-weight: 300;
}

.mat-form-field-should-float .mat-form-field-label {
  color: #2a3037;
}

.mat-calendar-body-selected,
.mat-form-field.mat-focused .mat-form-field-ripple {
  background: #739d44;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused .mat-form-field-label,
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #739d44;
}

.readOnlyField {

  // span{
  //     position: relative;
  // }
  .mat-select-value,
  .mat-input-element {
    font-family: "sf_ui_displaybold";
  }

  .fieldLabel,
  .fieldValue {
    color: #2a3037;
  }

  .fieldLabel {
    margin-right: 5px;
    font-size: 14px;
    font-family: "sf_ui_displayregular";
  }

  .fieldValue {
    font-size: 16px;
    font-family: "sf_ui_displaybold";
  }

  .fieldGroup {
    clear: both;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fieldIcon {
    position: absolute;
    margin-top: -3px;
    margin-left: 15px;
  }
}

.readOnlyField.blockFields {
  .fieldValue {
    font-size: 14px;
    color: #2a3037;
    font-family: "sf_ui_displaymedium";
  }

  .fieldLabel {
    display: block;
    font-size: 12px;
    color: #8a9199;
    font-family: "sf_ui_displayregular";
  }

  .fieldGroup {
    clear: both;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.certificates {
  width: 80px;
  height: 40px;
  display: block;

  img {
    height: 100%;
  }
}

.mat-form-field,
.mat-select {
  font-family: "sf_ui_displayregular" !important;
}

/* .mat-select-panel{
    margin-top: 30px;
} */

/*Buttons*/
.popupLink {
  font-family: "sf_ui_displaysemibold";
  cursor: pointer;
}

.greenColorIcon {
  color: #739d44;
}

.orangeColorIcon {
  color: #e9670e;
}

.greenColor {
  &.icon-other:before {
    color: #739d44;
  }
}

.greenColor {
  &.icon-other1:before {
    color: #739d44;
  }
}

.greenColor {
  &.icon-payments2:before {
    color: #739d44;
  }

  &.icon-transportation:before {
    color: #739d44;
  }

  &.icon-demand:before {
    color: #739d44;
  }
}

.commentAlert {
  &:after {
    content: "";
    width: 9px;
    height: 9px;
    background-color: #f57f23;
    position: absolute;
    border-radius: 50px;
    right: 0;
    margin-top: 3px;
    margin-right: 4px;
    border: 2px solid #fff;
  }
}

.btnBasic {
  font-family: "sf_ui_displaybold";
  font-size: 14px;
  color: #4a4a4a;
  padding: 0 !important;
  box-shadow: none !important;
  line-height: inherit !important;
  padding: 10px 15px !important;
}

.btnLink {
  font-family: "sf_ui_displaysemibold";
  font-size: 14px;
  color: #739d44;
  text-decoration: none;
}

.basicBtn {
  padding: 0 !important;
  width: auto;
  height: auto;
  min-width: auto !important;
  background: none;
  border: 0;
  box-shadow: none !important;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }

  &:first-child {
    margin-right: 0 !important;
  }

  [class^="icon2-"],
  [class*=" icon2-"],
  [class^="icon-"],
  [class*=" icon-"] {
    margin-right: 10px;
  }
}

.btn {
  margin-right: 20px !important;

  &:last-child {
    margin-right: 0 !important;
  }
}

// .roundBtn{
//     padding: 0 !important;
//     width: 40px;
//     height: 40px;
//     min-width: 40px !important;
//     border-radius: 50px !important;
// }
.btnPrimary {
  border-radius: 3px;
  background-color: #739d44;
  color: #f2f9fc;
  font-family: "sf_ui_displaysemibold";
  font-size: 14px;
  border: solid 1px #739d44 !important;
  padding: 8px 15px !important;
  line-height: normal !important;
  height: auto;
  box-shadow: none !important;
}

.btnSecondary {
  border-radius: 3px !important;
  background-color: #ffffff;
  color: #4a4a4a;
  font-family: "sf_ui_displaysemibold";
  font-size: 14px;
  border: solid 1px #739d44 !important;
  padding: 8px 15px !important;
  line-height: normal !important;
  height: auto;
  box-shadow: none !important;

  span {
    margin-right: 10px;
  }

  span.mat-button-wrapper {
    margin-right: 0;
  }

  &.greenBtn {
    height: 40px;
    padding: 0 15px !important;

    .mat-button-wrapper {
      color: #739d44;
    }

    &.smallBtn {
      height: 30px;
      padding: 0 10px !important;
    }
  }

  a {
    color: #4a4a4a;
    text-decoration: none;
  }
}

.btnTertiary {
  border-radius: 3px !important;
  background-color: transparent;
  color: #739d44;
  font-family: "sf_ui_displaysemibold";
  font-size: 14px;
  border: none;
  padding: 8px 15px !important;
  line-height: normal !important;
  height: auto;
  box-shadow: none !important;
  min-width: auto !important;

  .icon-right_arrow:before {
    margin-left: 5px;
    font-size: 12px;
  }

  .mat-icon {
    margin-top: -10px;
  }
}

.linkBtn {
  border: 0;
  border-radius: 0 !important;

  mat-button-toggle {
    margin-right: 30px;
    border-left: 0 !important;

    &.mat-button-toggle-checked,
    &:hover {
      background-color: transparent;
    }

    /* &:last-child{
            margin: 0;
        } */
    &.mat-button-toggle-checked .mat-button-toggle-label-content,
    &.mat-button-toggle-checked .mat-button-toggle-label-content>span:before {
      color: #4a4a4a;
    }

    button {
      font-family: "sf_ui_displayregular";
      font-size: 18px;
      color: #739d44;

      .mat-button-toggle-label-content {
        padding: 0;
        line-height: normal;
        transition: all 0.3s;

        >span:before {
          color: #739d44;
        }
      }
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: transparent !important;
  }

  .mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0;
  }

  .mat-ripple {
    display: none;
  }
}

.filterLinkBtn {
  border: 0;
  border-radius: 0 !important;

  mat-button-toggle {
    margin-right: 25px;
    border-left: 0 !important;

    &.mat-button-toggle-checked,
    &:hover {
      background-color: transparent;

      button {
        .mat-button-toggle-label-content {
          color: #739d44;
        }
      }
    }

    &:last-child {
      margin: 0;
    }

    // &.mat-button-toggle-checked .mat-button-toggle-label-content{
    //     box-shadow: inset 0px -4px 0px #092541;
    //     color: #4a4a4a;
    // }
    &.mat-button-toggle-checked .mat-button-toggle-label-content:after {
      content: "";
      width: 100%;
      height: 2px;
      display: block;
      background: #739d44;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    button {
      font-family: "sf_ui_displayregular";
      font-size: 15px;
      color: #4a4a4a;

      .mat-button-toggle-label-content {
        padding: 0;
        line-height: normal;
        transition: all 0.3s;
        position: relative;
      }
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
  .mat-button-toggle-appearance-standard {
    background-color: transparent !important;
  }

  .mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0;
  }

  .mat-ripple {
    display: none;
  }
}

.tableLinks {
  font-family: "sf_ui_displayregular";
  color: #739d44;
  cursor: pointer;
}

button {
  font-family: "sf_ui_displayregular";
}

/* Table comment section */
.commentActionPanel {
  padding: 10px 20px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.28);
  margin-top: -39px;
  margin-right: -10px;
  height: 300px;

  .icon-comment_green:before {
    color: #c7d1dc;
  }

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  button.mat-menu-item {
    padding: 0;
    margin: 0;
    line-height: normal;
    height: auto;
    font-size: 12px;
    color: #739d44;
    text-align: right;
    margin-top: 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }

    .mat-menu-ripple {
      display: none !important;
    }
  }

  .commentText {
    color: #4b4b4b;
    font-size: 16px;
    margin-right: 12px;
  }
}

.tooltipIcon {
  color: #739d44;
  width: 15px;
  height: 15px;
  border: 2px solid #739d44;
  border-radius: 100%;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  font-weight: bold;
  display: block;
  margin: auto;
}

.tooltipPanel {
  padding: 10px 20px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.28);
  margin-top: -40px;
  margin-left: -9px;

  .roadMap {
    min-width: 400px;
    width: 100%;
    background: #e5e5e5;
    height: 220px;
  }

  &.mat-menu-panel {
    max-width: 100%;
  }

  &.withBasicBtn {
    margin-top: -29px;
    margin-left: -20px;
  }

  .tooltipIcon {
    position: absolute;
  }

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  button.mat-menu-item {
    padding: 0;
    margin: 0;
    line-height: normal;
    height: auto;
    font-size: 12px;
    color: #739d44;
    text-align: right;
    margin-top: 10px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }

    .mat-menu-ripple {
      display: none !important;
    }
  }

  .tooltipText {
    .readOnlyField .fieldLabel {
      display: block;
    }
  }
}

/*Remove borders*/
.noBorders .mat-form-field-underline {
  display: none;
  border: 0;
}

/*Down arrow*/
.mat-select-arrow {
  position: relative;
  top: -4px;
  border: 0 !important;
}

.mat-select-arrow:after,
.mat-select-arrow:before {
  top: 100%;
  right: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.mat-select-arrow:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 7px;
  margin-left: -8px;
  margin-top: -2px;
}

.mat-select-arrow:before {
  border-color: rgba(67, 164, 15, 0);
  border-top-color: #000000;
  border-width: 7px;
  margin-left: -9px;
}

/*Text alignments*/
.textRight {
  text-align: right !important;
}

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

/* .permissionTable {
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
      background: #2a8fcb;
    }
    tbody
      tr
      td.mat-column-select:first-child
      .mat-checkbox-checked.mat-accent
      .mat-checkbox-background {
      background: #092541;
    }
  } */
.ORblock {
  position: relative;

  &.borderR:after {
    content: "OR";
    display: block;
    width: 50px;
    height: 40px;
    background: #fff;
    text-align: center;
    line-height: 40px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: 0;
    margin-right: -25px;
    font-size: 14px;
  }
}

.secondaryTabs {
  .mat-tab-header {
    background: transparent;
    border: 0;

    .mat-tab-label-content a {
      padding: 20px 0 5px;

      .mat-ripple-element {
        display: none;
      }
    }
  }
}

.hidden {
  display: none;
}

.disabledDiv {
  opacity: 0.5;
}

.divider {
  height: 1px;
  background: #eeeeee;
}

.mat-card {
  color: #4a4a4a;
}

/*Down arrow*/
.mat-select-arrow {
  position: relative;
  top: -4px;
  border: 0 !important;
}

.mat-select-arrow:after,
.mat-select-arrow:before {
  top: 100%;
  right: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.mat-select-arrow:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 7px;
  margin-left: -8px;
  margin-top: -2px;
}

.mat-select-arrow:before {
  border-color: rgba(67, 164, 15, 0);
  border-top-color: #000000;
  border-width: 7px;
  margin-left: -9px;
}

/*Text alignments*/
.textRight {
  text-align: right !important;
}

.textCenter {
  text-align: center !important;
}

/*Progress Bar*/
.customProgressbar {
  &.mat-progress-bar {
    height: 5px;
    border-radius: 50px;
    overflow: inherit;

    .mat-progress-bar-background {
      opacity: 0;
    }

    .mat-progress-bar-buffer {
      background-color: #daddd4;
      height: 3px !important;
      top: 1px;
    }
  }
}

/*Comment popup*/
.cardCheck {
  mat-checkbox {
    width: 80%;

    label {
      width: 100%;

      .mat-checkbox-label {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.commentBoxActions {
  position: relative;
  padding: 5px 5px 5px 5px !important;
  height: 60px;
  width: 100%;
  border: 1px solid #a0a2a5;
  border-radius: 5px;

  textarea {
    height: 50px;
    width: 85%;
    font-size: 14px;
  }

  textarea.mat-input-element {
    resize: none !important;
    overflow: auto;
  }

  button {
    position: absolute;
    right: 0;
    margin-right: 5px !important;
  }
}

.marginAuto {
  margin: auto !important;
}

.mat-checkbox-checkmark-path {
  stroke-dasharray: 19.91026 !important;
  stroke-width: 3.13333px !important;
}

/*common classes*/
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #739d44;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #739d44;
}

.mat-radio-outer-circle {
  border-color: #9b9b9b;
  width: 18px !important;
  height: 18px !important;
  border-width: 1px !important;
}

.mat-radio-inner-circle {
  height: 18px !important;
  width: 18px !important;
}

.mat-radio-label-content {
  padding-left: 5px !important;
}

.catalogImgUploadBtn {
  width: 120px;
  height: 120px;
  border: 2px solid #c7d1dc;
  position: relative;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 6px;
  }

  .imgUploadActions {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -20px;
    margin-right: 10px;

    .roundBtn {
      background: #fff;
      border: 2px solid #c7d1dc;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      i:before {
        color: #739d44;
      }
    }
  }
}

.mat-form-field-required-marker {
  color: #d0021b;
}

.imgIcon {
  width: 100%;
  margin: auto;
  display: block;
  margin-top: 5px;
}

.mat-error {
  font-size: 12px;
}

.displayBlock {
  display: block;
}

.borderR {
  border-right: 1px solid #c7d1dc;
}

.vAlignTop {
  vertical-align: top !important;
}

.w-100per .mat-form-field-infix {
  width: 100%;
}

.w-490 {
  width: 490px !important;
}

.pageLeft {
  margin-right: 20px;
}

.pageLeft,
.pageRight {
  margin-top: 15px;
}

.pageHeader {

  >span,
  h2 {
    margin-right: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.panelHeader {

  >span,
  h3 {
    margin-right: 15px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.customBadge {
  .mat-badge-content {
    background-color: #3eb2f7;
    border-radius: 3px;
    color: #fff;
    min-width: 55px;
    max-width: 100px;
    height: 22px;
    position: relative;
    font-family: "sf_ui_displayregular";
    top: 5px !important;
    right: 0 !important;
  }
}

.pd-20 {
  padding: 20px;
}

.pd-10 {
  padding: 10px;
}

.pdt-0 {
  padding: 0;
}

.pdb-15 {
  padding-bottom: 15px;
}

.pdb-0 {
  padding-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb--30 {
  margin-top: -30px !important;
}

.pdtlr-20 {
  padding: 20px 20px 0;
}

.pdlr-20 {
  padding: 0 20px !important;
}

.pdlr-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pdr-0 {
  padding-right: 0;
}

.pdr-20 {
  padding-right: 20px;
}

.pdr-50 {
  padding-right: 50px;
}

.pdl-50 {
  padding-left: 50px;
}

.pd-0 {
  padding: 0;
}

.mr-30 {
  margin-right: 30px;
}

.w-20 {
  width: 20px;
}

.w-180 {
  width: 180px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-0 {
  margin-right: 0 !important;
}

.pdr-0 {
  padding-right: 0 !important;
}

.mr-15 {
  margin-right: 15px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.ml-40 {
  margin-left: 40px;
}

.ml--10 {
  margin-left: -10px;
}

.ml-25 {
  margin-left: 25px;
}

.mt--18 {
  margin-top: -18px;
}

.mt--20 {
  margin-top: -20px;
}

.mt-0 {
  margin-top: 0;
}

.mt--10 {
  margin-top: -10px;
}

.mt--5 {
  margin-top: -5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt--30 {
  margin-top: -30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px;
}

.logoText {
  font-family: "sf_ui_displaybold";
}

.fs-24 {
  font-size: 24px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.alignFlexEnd {
  justify-content: flex-end;
}

.lightText {
  color: #858585;
}

.fw-400 {
  font-family: "sf_ui_displaymedium";
}

.fw-600 {
  font-family: "sf_ui_displaysemibold";
}

.bgNone {
  background: none;
}

.imgLabel {
  color: #4a4a4a;
  font-size: 16px;
  font-family: "sf_ui_displayregular";
  margin-bottom: 10px;

  >span {
    margin-right: 10px;
    font-size: 16px;
  }
}

.rBorder {
  border-right: 1px solid #d9d9d9;
}

.infoBlocks {

  .readOnlyField,
  .formControl {
    margin-right: 35px;
    padding-right: 35px;
  }
}

.editableInput {
  padding: 15px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;

  &:focus {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    outline: 0;
  }
}

.editableTd {
  &.multipleInput {
    input {
      display: inline-block;
      width: 48%;
      margin-right: 2%;

      &.newUpdate {
        // background-color: rgba(233, 103, 15, .2);
      }

      &:disabled {
        color: #9b9b9b;
        font-family: "sf_ui_displaymedium";
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .mat-form-field-wrapper {
    padding: 0;

    .mat-form-field-infix {
      border: 0;
      padding: inherit !important;
    }
  }
}

.editableTd:hover,
.editableTd:focus {
  box-shadow: inset 0px 0px 0px 2px #739d44 !important;
}

u {
  text-decoration: none;
}

.pageInfo {
  &.withLightBg {
    background-color: #f9f9f9;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

    .mat-accordion .mat-expansion-panel:last-of-type {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .mat-accordion .mat-expansion-panel:first-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .mat-expansion-panel-content {
      clear: both;
    }
  }
}

.mat-card {
  font-family: "sf_ui_displayregular" !important;
  padding: 20px !important;
}

.mBold {
  font-family: "sf_ui_displaysemibold" !important;
}

.regularFont {
  font-family: "sf_ui_displayregular";
}

.xlBold {
  font-family: "sf_ui_displaybold" !important;
}

.newMsgIndicator {
  width: 12px;
  height: 12px;
  background-color: #3eb2f7;
  border-radius: 50%;
}

.wAuto {
  width: auto;
}

.pullRight {
  float: right;
}

.dateRange {
  .mat-icon {
    vertical-align: middle;
    margin-top: -15px;

    span {
      font-weight: bold;
    }
  }
}

.dateRange .w-180 .mat-form-field-wrapper .mat-form-field-infix {
  width: 90px !important;
}

.withoutLabel {
  .mat-form-field-infix {
    border: 0;
    padding: 0 0 5px;
    top: 1px;
    text-align: left;
  }

  .mat-form-field-underline {
    top: 18px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.horizontalGroup {
  .mat-form-field-infix {
    border: 0;
    padding: 0 0 5px;
    top: 1px;
    text-align: left;
  }

  .mat-form-field-underline {
    top: 18px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: none !important;
}

.mat-expansion-indicator::after {
  margin-top: -5px;
}

/* Collapsible panel */
.mat-expansion-panel-body {
  padding: 20px !important;
  background: #fff;
  position: relative;
}

.mat-expansion-panel-body:after {
  bottom: 100%;
  right: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-right: 80px;
}

.mat-expansion-panel-header {
  padding: 0 20px !important;
}

.CollapseBtn {
  font-family: "sf_ui_displayregular";
  font-size: 18px;
  color: #739d44;
}

.mat-expansion-indicator::after {
  color: #739d44;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
  background: none !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.customTable {
  border-collapse: collapse;

  th {
    border-top: 1px solid #739d44;
    padding: 8px 10px !important;
    border-bottom: 1px solid #739d44 !important;
    border-right: 1px solid #e3e2e2;
    border-left: 1px solid #e3e2e2;
    color: #4b4b4b;
    font-size: 14px;
    font-family: "sf_ui_displaysemibold";
    line-height: 1.75;
    letter-spacing: 0.1px;
    text-align: left;
  }

  td {
    //padding: 0px !important;
    border: 1px solid #e3e2e2;
    font-size: 14px;

    &.expandCta {
      position: relative;

      span {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;

        &::before {
          content: "";
          width: 0;
          height: 8px;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          position: absolute;
          top: 50%;
          right: 12px;
        }

        &.collapsed {
          &::before {
            border-top: 8px solid #9b9b9b;
            margin-top: -3px;
          }
        }

        &.expanded {
          &::before {
            border-bottom: 8px solid #9b9b9b;
            margin-top: -10px;
          }
        }
      }
    }

    input {
      font-size: 14px;
      border: none;

      width: 92%;
      height: 40px;
      padding: 8px 4%;

      &:focus {
        outline-offset: -2px;
        outline-color: #739d44;
        outline-width: 2px;
      }
    }

    &.tablePadding {
      padding: 8px 10px !important;
    }
  }

  .element-details {
    transition: all 0.3s ease;
    padding: 0;
  }

  tr {
    &.close {
      td {
        padding: 0 !important;
        border: 0;

        .element-details {
          height: 0;
          overflow: hidden;
          opacity: 0;
        }
      }
    }

    &.open {
      td {
        padding: 0 !important;

        .element-details {
          //height: 170px;
          opacity: 1;
          //overflow-y: auto;
        }
      }
    }
  }

  .addBtn {
    border-radius: 3px;
    border: solid 1px #4a4a4a;
    background-color: #ffffff;
    font-size: 16px;
    font-family: "sf_ui_displaysemibold";
    color: #2a3037;
    padding: 5px 16px;
    cursor: pointer;
  }

  ::placeholder {
    color: #c4c4c4;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #c4c4c4;
  }

  ::-ms-input-placeholder {
    color: #c4c4c4;
  }

  .editableTd {
    &.multipleInput {
      input {
        padding: 10px 4%;
      }
    }
  }

  .readOnlyField .fieldLabel,
  .readOnlyField .fieldValue {
    font-size: 14px;
  }
}

.deleteBtn {
  cursor: pointer;
}

.basicCards {
  border-radius: 3px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;

  &.supplierProfileImg {
    width: 250px;
    height: 160px;
    overflow: hidden;
    margin-right: 20px;

    img {
      height: 100%;
      margin: auto;
      display: block;
    }
  }
}

.reminderFilter {
  [class^="icon-"] {
    margin-right: 10px;
    margin-bottom: 2px;

    &:before {
      font-size: 20px;
      color: #739d44;
      font-weight: 600;
    }
  }

  .mat-menu-item {
    line-height: 40px;
    height: 40px;
    border-bottom: 1px solid #eeeeee;
    padding: 0 12px;

    &:last-child {
      border-bottom: 0;
    }

    span {
      vertical-align: middle;
      display: inline-block;
    }
  }

  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.customList {
  .customItems {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    width: 100%;

    .mat-form-field {
      display: block;
    }

    &:last-child {
      margin: 0;
      border: 0;
      padding: 0;
    }

    &.noBorder {
      border: 0;
      padding: 0;
    }

    &.editableCustomItem {
      padding-bottom: 0;

      .mat-form-field-infix {
        border-top: 0;
      }

      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 5px;
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-appearance-legacy .mat-form-field-label {
        margin-top: 6px;
      }
    }

    >.reminderText {
      margin: 0 15px;
      width: 100%;

      div {
        margin-bottom: 5px;
      }

      .statusTag {
        margin-right: 10px;
        display: inline-block;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .mat-icon-button {
      width: 20px;
      height: 20px;

      .mat-icon,
      .mat-icon-button i {
        line-height: 0px;
      }
    }
  }
}

.msgBox {
  border-bottom: 1px solid #eeeeee !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s;

  &.unreadMsg {
    background-color: #fff;

    .msgHead {
      span {
        font-family: "sf_ui_displaybold";
      }
    }

    .fieldValue {
      font-family: "sf_ui_displaybold";
    }

    .newMsgIndicator {
      opacity: 1;
    }
  }

  &.readMsg {
    background-color: #f9f9f9;

    .msgHead {
      span {
        font-size: 16px;
        font-family: "sf_ui_displayregular";
      }
    }

    .fieldValue {
      font-family: "sf_ui_displayregular";
    }

    .newMsgIndicator {
      opacity: 0;
    }
  }

  &:first-child {
    border-radius: 3px 3px 0 0;
    margin-top: 0;
  }

  &:last-child {
    border-radius: 0 0 3px 3px;
  }

  .icon-discard {
    padding-bottom: 5px;
    display: inline-block;
  }

  .lightText {
    .mat-icon-button {
      margin-right: -10px;
    }
  }

  .msgHead {
    span {
      font-size: 16px;
    }
  }

  .msgActions {
    opacity: 0;
    transition: all 0.3s;
    margin-top: -30px;
  }

  &:hover {
    z-index: 1;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.2) !important;
    background-color: #fff;

    .msgActions {
      opacity: 1 !important;
      margin-top: -5px;
      transition: all 0.1s;
    }
  }

  .fieldLabel,
  .fieldValue {
    font-size: 14px;
    font-family: "sf_ui_displayregular";
  }

  .btn {
    padding: 5px 10px !important;
    margin-right: 10px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.lgTabs {
  .mat-tab-body-wrapper {
    margin-left: -20px;
    margin-right: -20px;
  }

  mat-card.msgBox {
    margin-left: 20px;
    margin-right: 20px;
  }

  mat-tab-header {
    background: #f9f9f9;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;

    .mat-tab-nav-bar,
    .mat-tab-header {
      border-bottom: 1px solid #dde1e6;
    }

    .mat-tab-label-content {
      display: block;

      .tabCount {
        font-size: 44px;
        font-family: "sf_ui_displayregular";
        color: #4a4a4a !important;
      }

      .label {
        font-family: "sf_ui_displaymedium";
        font-size: 18px;
        margin-top: -15px;
        color: #4a4a4a !important;
      }
    }

    .mat-tab-label {
      padding: 20px 40px;
      height: auto;
      opacity: 1;

      &.mat-tab-label-active {
        box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.1);
        background-color: #f9f9f9;

        .tabCount {
          color: #2a3037 !important;
        }
      }
    }

    .mat-ink-bar {
      background-color: transparent !important;
    }
  }
}

.mdTabs {
  mat-tab-header {
    background: #f9f9f9;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
    border-bottom: 1px solid #eeeeee;

    .mat-tab-label-content {
      display: block;

      a {
        font-family: "sf_ui_displaymedium";
        font-size: 16px;
        font-weight: 600;
        color: #4a4a4a;
        text-decoration: none;
        padding: 20px 0;
        display: inline-block;
        position: relative;

        &:after {
          content: "";
          width: 100%;
          height: 2px;
          background: transparent;
          bottom: 0;
          left: 0;
          position: absolute;
        }
      }

      .mat-ripple {
        display: none;
      }

      .mat-ripple-element {
        display: none;
      }
    }

    .mat-tab-label {
      padding: 0 20px;
      position: relative;
      height: auto;
      opacity: 1;
      min-width: auto !important;

      &.mat-tab-label-active {
        a:after {
          background: #739d44;
        }
      }
    }

    .mat-ink-bar {
      background-color: transparent !important;
      display: none;
    }
  }
}

.productCard {
  position: relative;
  width: 320px;
  height: auto;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
  margin: 10px 10px 10px 1px;
  padding: 0 !important;
  border-radius: 0 !important;

  .overlapHeader {
    margin-top: -26px;
    position: relative;
    padding: 0 15px !important;

    .mat-card-title {
      color: #fff;
      font-size: 14px;
      font-family: "sf_ui_displayregular";
      font-weight: normal;
    }
  }

  .mat-card-header {
    padding: 20px 15px 5px;

    .mat-card-header-text {
      margin: 0;
    }

    .mat-card-header .mat-card-title {
      margin-bottom: 5px;
    }

    .cardAmt {
      color: #e9670e;
      font-size: 16px;
      font-family: "sf_ui_displayregular";
    }

    .mat-card-title {
      margin-bottom: 5px !important;
      margin-top: -5px;
    }

    .mat-card-subtitle {
      margin-bottom: 0;
    }
  }

  .mat-card-content {
    padding: 0 15px 20px;
    top: -25px;
  }

  .cardActions {
    input {
      width: 60px;
      border: 0;
      border-bottom: 1px solid #c7d1dc;
      font-size: 14px;
      margin-right: 10px;
    }

    button {
      font-size: 12px;
      padding: 4px 10px !important;
      min-width: auto;
      border-radius: 3px;
    }
  }

  .cardImg {
    position: relative;
    width: 100%;
    height: 135px;
    overflow: hidden;
    top: 0;
    left: 0;

    img {
      position: absolute;
      width: 100%;
      margin: 0;
    }

    &.fixedImg {
      display: flex;
      align-items: center;

      img {
        position: relative;
        width: auto !important;
        margin: auto;
        display: block;
        margin-top: 20px;
      }
    }
  }

  .fillChips .mat-chip-list-wrapper {
    margin: 0;

    .mat-chip.mat-standard-chip {
      background-color: #f57f23;
      color: #fff;
      margin: 5px 15px;
      position: absolute;
      border-radius: 0;
      z-index: 1;
    }
  }

  .borderedChips {
    width: 100%;
    border-top: 1px solid #c7d1dc;
    padding-top: 10px;
    margin-top: 4px !important;

    .mat-chip-list-wrapper {
      margin: 0;

      .mat-chip.mat-standard-chip {
        background-color: #fff;
        color: #2a3037;
        font-size: 12px;
        border: 1px solid #c7d1dc;
        min-height: auto;
        padding: 10px 10px;
        margin: 0 10px 0 0;
      }
    }
  }
}

.mat-chip.mat-standard-chip {
  background-color: transparent;
  font-size: 12px;
  border: 1px solid #e3e2e2;
  font-family: "sf_ui_displaybold";

  i {
    margin-left: 10px;
    cursor: pointer;
  }
}

.productCardForInfo {
  position: relative;
  height: auto;
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: 100%;
  box-shadow: none !important;

  .mat-card-header {
    padding: 0;

    .mat-card-header-text {
      margin: 0;
    }

    .mat-card-header .mat-card-title {
      margin-bottom: 5px;
    }

    .mat-card-title {
      margin-bottom: 5px !important;
      margin-top: -5px;
      font-family: "sf_ui_displaymedium";
    }

    .mat-card-subtitle {
      margin-bottom: 0;
    }
  }

  .mat-card-content {
    padding: 10px 0 0;

    p {
      margin-top: 40px;
      margin-bottom: 5px;
    }

    .smallLink {
      font-size: 14px;
      text-decoration: none;
      color: #739d44;
      font-family: "sf_ui_displaymedium";
    }
  }

  .cardActions {
    input {
      width: 60px;
      border: 0;
      border-bottom: 1px solid #c7d1dc;
      font-size: 14px;
      margin-right: 10px;
    }

    button {
      font-size: 12px;
      padding: 4px 10px !important;
      min-width: auto;
      border-radius: 3px;
    }
  }

  .cardImg {
    position: relative;
    width: 100%;
    height: 135px;
    overflow: hidden;
    top: 0;
    left: 0;

    img {
      position: absolute;
      width: 100%;
      margin: 0;
    }
  }

  .fillChips .mat-chip-list-wrapper {
    margin: 0;

    .mat-chip.mat-standard-chip {
      background-color: #f57f23;
      color: #fff;
      margin: 5px 15px;
      position: absolute;
      border-radius: 0;
      z-index: 1;
    }
  }

  .borderedChips {
    width: 100%;
    border-top: 1px solid #c7d1dc;
    padding-top: 10px;
    margin-top: 4px !important;

    .mat-chip-list-wrapper {
      margin: 0;

      .mat-chip.mat-standard-chip {
        background-color: #fff;
        color: #2a3037;
        font-size: 12px;
        border: 1px solid #c7d1dc;
        min-height: auto;
        padding: 10px 10px;
        margin: 0 10px 0 0;
      }
    }
  }
}

.swiper-slide {
  margin-right: 0 !important;
  // &:first-child{
  //     .productCard{
  //         margin-left: 0;
  //     }
  // }
}

.swiper-container {
  width: 100%;

  .swiper-button-next {
    background: rgba(255, 255, 255, 0.9) !important;
    width: 40px;
    height: 60px;
    border: 1px solid #739d44;
    border-radius: 3px 0 0 3px;
    margin-right: -11px;

    &.swiper-button-disabled {
      border-color: #c7d1dc;

      &:before {
        border-color: #c7d1dc;
      }
    }

    &:before {
      content: "";
      position: relative;
      border: solid #739d44;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin: 25px 0px 0px 10px;
    }
  }

  .swiper-button-prev {
    background: rgba(255, 255, 255, 0.9) !important;
    width: 40px;
    height: 60px;
    border: 1px solid #739d44;
    border-radius: 0 3px 3px 0;
    left: 0;

    &.swiper-button-disabled {
      border-color: #c7d1dc;

      &:before {
        border-color: #c7d1dc;
      }
    }

    &:before {
      content: "";
      position: relative;
      border: solid #739d44;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin: 25px 10px 0px 15px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 50%;
    margin-top: -50px;
  }

  .swiper-pagination {
    position: relative;
    margin-top: 20px;
  }

  .swiper-pagination-bullet-active {
    background: #d8d8d8;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-first {
    border: 0 !important;
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
  }

  .swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 1;
  }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0 0 0 4px !important;
}

.mat-tree {
  padding-right: 20px;

  .mat-nested-tree-node {
    ul {
      padding-left: 0;

      &>mat-tree-node,
      &>mat-nested-tree-node>li {
        padding-left: 20px;
      }

      .mat-tree-node {
        border: 0;
      }
    }
  }

  .mat-tree-node {
    position: relative;
    font-size: 16px;
    border-bottom: 1px solid #e3e2e2;

    button.mat-icon-button {
      width: 100%;
      height: auto;
      line-height: 50px;
      border-radius: 0;
      position: absolute;
      right: 0;

      .mat-button-wrapper {
        float: right;
      }
    }
  }
}

/*Process wizard*/
/* .mat-horizontal-stepper-header{
    &[ng-reflect-state=edit]:after, &[ng-reflect-selected=true]:before, &[ng-reflect-state=edit]:before, &[ng-reflect-selected=true][ng-reflect-state=edit]:after{
        border-color: #739d44;
    }
    pointer-events: none !important;
}
.mat-stepper-horizontal-line{
    display: none;
}
.mat-step-header{
    &:hover{
        background-color: transparent !important;
    }
    .mat-ripple{
        display: none !important;
    }
    .mat-step-icon {
        background-color: #DADDD4;
        color: #DADDD4;
        border: 2px solid #DADDD4;
        height: 12px;
        width: 12px;
        &.mat-step-icon-selected{
            background-color: #fff;
            color: rgba(255,255,255,0);
            border: 2px solid #739d44;
            font-size: 0;
        }
        &.mat-step-icon-state-edit{
            background-color: #739d44;
            color: rgba(255,255,255,0);
            border: 2px solid #739d44;
        }
    }
    .mat-step-label {
        font-family: 'sf_ui_displaysemibold';
        font-size: 14px;
        color: #4a4a4a !important;
        margin-top: -60px;
        &.mat-step-label-selected{
            color: #739d44 !important;
        }
    }
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before
{
    top: 32px !important;
    width: calc(50% - 8px) !important;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 32px !important;
} */
.regRight {
  .mat-horizontal-stepper-header-container {
    margin-top: 0;
  }
}

.mat-horizontal-stepper-header-container {
  margin-bottom: 40px;
  margin-top: 40px;
}

.mat-horizontal-stepper-header {
  pointer-events: none !important;
  float: left !important;
  width: 200px;
  height: auto !important;
  display: block !important;
  padding: 0 0 0 0 !important;
  overflow: inherit !important;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #daddd4;
    position: absolute;
    top: 7px;
    right: 0;
    left: 50%;
    margin-left: 8px;
  }

  &[ng-reflect-active="true"] {

    .mat-step-icon,
    .mat-step-icon-not-touched {
      background-color: #fff;
      border-color: #739d44;
    }
  }

  &[ng-reflect-state="edit"] {

    .mat-step-icon,
    .mat-step-icon-not-touched {
      background-color: #739d44;
      border-color: #739d44;
    }

    &:after {
      background-color: #739d44;
    }
  }

  &:last-child:after {
    display: none;
  }
}

.mat-stepper-horizontal-line {
  display: none;
}

.mat-step-header {
  &:hover {
    background-color: transparent !important;
  }

  .mat-ripple {
    display: none !important;
  }

  .mat-step-icon,
  .mat-step-icon-not-touched {
    background-color: #daddd4;
    color: transparent;
    border: 2px solid #daddd4;
    height: 12px;
    width: 12px;
    position: relative;
    margin-right: inherit !important;
    margin: auto !important;
    display: block !important;

    &.mat-step-icon-selected {
      background-color: #fff;
      color: rgba(255, 255, 255, 0);
      border: 2px solid #739d44;
      font-size: 0;
    }

    &.mat-step-icon-state-edit {
      background-color: #739d44;
      color: rgba(255, 255, 255, 0);
      border: 2px solid #739d44;
    }
  }

  .mat-step-label {
    display: block !important;
    font-family: "sf_ui_displaysemibold";
    font-size: 14px;
    color: #4a4a4a !important;
    text-align: center;
    margin-top: -45px;

    &.mat-step-label-selected {
      color: #739d44 !important;
    }
  }
}

.RegistrationPanel {
  background-color: #ffffff;
  overflow: hidden;
  margin: 0 auto;
  padding: 60px 0 0 0;

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    width: 50%;
  }

  .iconicCheckBoxGroup {
    margin-top: 110px;
    margin-bottom: 40px;

    .iconicCheckBoxContent {
      .iconicCheckBox {
        label.mat-checkbox-layout {
          text-align: center;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;

          span.mat-checkbox-label {
            display: block;
            position: relative;
            top: -90px;
            font-size: 18px;
            font-family: "sf_ui_displaymedium";
            color: #2a3037;
          }

          .mat-checkbox-inner-container {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: #fff;
            border: 1px solid #739d44;
            top: -15px;
            margin: auto;

            .mat-checkbox-frame {
              opacity: 0;
            }

            .mat-checkbox-background {
              border-radius: 100%;
            }

            .mat-checkbox-ripple {
              .mat-ripple-element {
                border-radius: 100%;
              }

              .mat-checkbox-background,
              .mat-checkbox-frame {
                border-radius: 100%;
                border: 1px solid #739d44;
              }
            }
          }
        }

        &:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
          background: #739d44;
        }

        &.mat-checkbox-checked.mat-accent {
          .mat-checkbox-background {
            background: #114267;

            .mat-checkbox-checkmark {
              fill: #fafafa;
              width: 22px !important;
              margin: auto;
            }
          }

          &:before {
            opacity: 1 !important;
          }

          &:after {
            border-color: #114267 !important;
            background-color: #f7fcff !important;
            box-shadow: 0 0 4px 5px rgba(178, 191, 171, 0.14);
          }

          .mat-checkbox-inner-container {
            border-color: #fff;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

app-business-unit .mat-horizontal-content-container {
  display: none !important;
}

/*File Upload*/
.upload-box {
  .upload-box-content {
    .drop-container {
      padding: 30px;
      border: 2px dotted #9b9b9b;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-left: 30px;

      .upload-button {
        border: 1px solid #739d44;
        border-radius: 3px;
        display: block;
        width: 80px;
        height: 35px;
        line-height: 35px;
        margin: 10px auto;
        font-size: 14px;
        color: #4a4a4a;
        font-weight: bold;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          background-color: #739d44;
          color: #fff;
        }

        input {
          display: none;
        }
      }

      p {
        font-size: 14px;
        font-family: "sf_ui_displaysemibold";

        >label {
          display: block;
        }
      }
    }

    .upload-item {
      font-size: 12px;
      padding-left: 30px;
      position: relative;
      margin-bottom: 30px;

      .filename {
        img {
          display: block;
          width: 21px;
          position: absolute;
          left: 0;
        }

        span {
          font-size: 14px;
          font-family: "sf_ui_displaysemibold";
          color: #4a4a4a;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
      }

      .progress-content {
        width: 100%;
        height: 5px;
        background: #daddd4;
        display: block;
        border-radius: 50px;
        overflow: hidden;

        .bar {
          background: #216aa1;
          width: 100%;
          height: 5px;
          display: block;
        }
      }

      .progress-text-content {
        .progress-text {
          font-size: 12px;
          color: #9b9b9b;
          text-align: right;
          width: 100%;
          display: block;
        }
      }

      .upload-item-content {
        padding-right: 10px;

        .btnRemove {
          position: absolute;
          right: 0;
          top: 0;
          padding: 0;
          margin: 0;
          max-width: 0;
          min-width: auto;
          color: #9b9b9b;
          font-weight: bolder;
          margin-top: 5px;
        }
      }
    }
  }
}

/*POPUP*/
.mat-dialog-container {
  border-radius: 8px !important;
  color: #2a3037 !important;

  .mat-dialog-actions {
    padding: 25px 0 0 0;
    margin-bottom: 0;
  }

  .RegistrationPanel {
    width: auto !important;
  }
}

.popupHead {
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
    margin-right: -5px;
    border: 0;
    padding: 13px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:before,
    &:after {
      content: "";
      width: 2px;
      height: 15px;
      background: #2a3037;
      position: absolute;
      display: block;
      top: 0;
      margin-top: 5px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media (max-width: 1170px) {

  .infoBlocks .readOnlyField,
  .infoBlocks .formControl {
    margin-right: 15px;
    padding-right: 15px;
  }

  .basicCards {
    border-radius: 3px !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;

    &.supplierProfileImg {
      width: 100px;
      height: 60px;

      img {
        height: 100%;
      }
    }
  }

  .pdr-50 {
    padding-right: 20px;
  }

  .pdl-50 {
    padding-left: 20px;
  }

  .productCard {
    width: 100%;
  }
}

@media (max-width: 1279px) {
  .global-action {
    transform: scale(0.7);
    bottom: 0 !important;
    right: 0 !important;
  }

  .readOnlyField .w-180 {
    width: 100px;
  }

  .infoBlocks .readOnlyField .mat-form-field-infix {
    width: 100px !important;
  }

  .pageInfo {
    .mat-form-field-infix {
      width: 150px;
    }

    .w-180 {
      width: 150px;
    }
  }

  .readOnlyField {
    .w-180 {
      width: 100px;
    }
  }

  .linkBtn mat-button-toggle button {
    font-size: 14px;
  }

  .processStatus li {
    font-size: 14px !important;
    margin-bottom: 15px !important;
  }

  .settingsCards .basicCards {
    padding: 10px !important;
  }

  .settingsCards .btn {
    font-size: 12px !important;
    white-space: normal !important;
  }

  .mat-radio-label-content {
    font-size: 14px;
  }

  .dropped-po-wrapper .freight-charge .add-freight-charge {
    position: absolute;
    right: -5px;
    top: 4px;
    z-index: 1;
  }
}

@media (max-width: 1600px) {
  .linkBtn mat-button-toggle button {
    font-size: 16px;
  }

  input {
    font-size: 14px;
  }

  .fs-24 {
    font-size: 20px !important;
  }

  .w-100per .mat-form-field-infix {
    width: 180px;
  }

  .infoBlocks .readOnlyField,
  .infoBlocks .formControl {
    margin-right: 15px;
    padding-right: 15px;
  }

  .basicCards {
    border-radius: 3px !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;

    &.supplierProfileImg {
      width: 100px;
      height: 60px;

      img {
        height: 100%;
      }
    }
  }

  .pdr-50 {
    padding-right: 20px;
  }

  .pdl-50 {
    padding-left: 20px;
  }

  .productCard {
    width: 100%;
  }

  .mat-cell,
  .mat-header-cell,
  .mat-form-field {
    font-size: 12px !important;
  }

  .userName[_ngcontent-c2] {
    font-size: 13px;
  }

  // (RD)PMB-6554 New header and navigation menu bar for Procurant Commerc
  // .mat-nav-list .mat-list-item .mat-list-item-content > span {
  //   font-size: 16px;
  // }
  // .mat-nav-list {
  //   .mat-list-item {
  //     height: 48px !important;
  //     .mat-list-item-content {
  //       padding: 0 15px !important;
  //     }
  //   }
  // }
  .contentWrapper {
    padding: 65px 0 20px 45px;
  }

  .table .mat-checkbox-inner-container {
    width: 15px;
    height: 15px;
  }

  .readOnlyField {

    .fieldLabel,
    .fieldValue {
      font-size: 12px;
    }
  }

  .CollapseBtn {
    font-size: 14px;
  }

  .fs-16 {
    font-size: 14px !important;
  }

  .fs-14 {
    font-size: 12px !important;
  }

  .fs-18 {
    font-size: 16px !important;
  }

  .readOnlyField .fieldGroup {
    margin-bottom: 5px;
  }

  .dateRange .mat-icon {
    margin-top: -8px;
    margin-right: 4px;
  }

  .filterLinkBtn mat-button-toggle button {
    font-size: 16px;
  }

  .tdHighlight span {
    font-size: 20px;
  }

  .table .mat-footer-cell {
    font-size: 14px;
  }

  .btn {
    font-size: 12px !important;
  }

  .customList .customItems>.reminderText .statusTag {
    margin-right: 5px;
    font-size: 10px !important;
    padding: 2px 8px !important;
    margin-bottom: 5px !important;
  }

  .infoBlocks .readOnlyField,
  .infoBlocks .formControl {
    margin-right: 10px;
  }

  .dateRange .mat-icon span {
    font-size: 16px !important;
  }

  .fs-20 {
    font-size: 18px !important;
  }

  .infoBlocks .readOnlyField,
  .infoBlocks .formControl {
    margin-right: 15px;
    padding-right: 15px;
  }

  .mat-select-arrow:before {
    border-width: 5px;
  }

  .mat-select-arrow:after {
    border-width: 5px;
  }

  .table {
    .mat-column-actions {
      width: 12%;
    }
  }

  .commentContent {

    .receiverContent,
    .senderContent {
      padding: 15px;
      width: 80%;

      .paragraph {
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }

  .msgBox {
    .msgBoxMsg {
      width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .headerWithIcon {
    margin-bottom: 15px;
  }

  .RegistrationPanel .iconicCheckBoxGroup .iconicCheckBoxContent {
    margin-right: 60px !important;
  }

  .pdlr-60 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .processStatus li {
    font-size: 14px !important;
    margin-bottom: 15px !important;
  }
}

@media (min-width: 1601px) {
  .msgBox {
    .msgBoxMsg {
      width: 700px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@media (min-width: 1281px) and (max-width: 1600px) {
  .infoBlocks .readOnlyField .mat-form-field-infix {
    width: 180px !important;
  }

  .w-180 {
    width: 180px;
  }
}

.customisedExpansion-panel {
  .CollapseBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-right: 30px !important;
  }
}

.cancelBtnExp {
  margin-top: 20px;
  position: absolute;
  right: 0;
  margin-right: -19px;
  top: -14px;
}

// In the button structure Angular Material builds various sub components and the last one is a 'div' with css class mat-button-focus-overlay
// Overwrite classes 'cdk-focused' and 'cdk-program-focused'
.mat-button-focus-overlay {
  background-color: transparent !important;
}

/*
.createCatlogCss{
  ::ng-deep .mat-select-panel {
    margin-top: 0px !important;
}
::ng-deep .mat-select-search-inside-mat-option .mat-select-search-clear {
    top: 0px !important;
}
} */

simple-snack-bar span {
  margin: auto;
  text-align: center;
}

.custome-readonly-block {
  .mat-select-disabled .mat-select-value {
    color: black;
  }

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: black;
  }
}

.placeHolderSelect {
  .mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 1.125;
    color: rgba(0, 0, 0, 0.87);
    font-family: "sf_ui_displayregular" !important;
  }
}

.mat-tooltip {
  height: auto;
  // overflow: visible;
  overflow-wrap: break-word;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #D9d9d9;
  border-radius: 5px;
}

.blur-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.blur-out {
  -webkit-animation: text-blur-out 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-blur-out 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.text-trackin {
  -webkit-animation: tracking-in-contract-bck 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.text-trackout {
  -webkit-animation: tracking-out-expand-fwd 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: tracking-out-expand-fwd 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.task-center-click {
  color: #739d44 !important;
  font-size: 15px !important;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-webkit-keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
    filter: blur(0.01);
  }

  100% {
    -webkit-filter: blur(12px) opacity(0%);
    filter: blur(12px) opacity(0%);
  }
}

@keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
    filter: blur(0.01);
  }

  100% {
    -webkit-filter: blur(12px) opacity(0%);
    filter: blur(12px) opacity(0%);
  }
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

@keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

.header-tab-bar {
  padding: 15px;
}

//Check Box Black

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: black !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: black !important;
}

/*Filter box*/
.filterBox {
  &.dashboardFilter {
    min-width: 300px;
    max-width: 300px;
  }

  padding: 20px;
}

.mat-form-field-infix {
  input {
    height: 100%;
  }
}

/* kendo */
::ng-deep .k-grid tr.k-state-selected>td {
  color: #656565;
  background: none;
}

.mat-cell,
.mat-header-cell,
.mat-form-field,
.divTableHead,
.divTableCell,
.customTable tr th,
.customTable tr td {
  font-size: 12px !important;
}

/* kendo */
.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-desc-sm,
.k-grid-header .k-header>.k-link>.k-icon.k-i-sort-asc-sm {
  position: absolute !important;
  right: 0px !important;
}

.k-sort-order {
  display: none !important;
}

.upload-doc-chips {
  .mat-chip-list-wrapper {
    justify-content: center !important;
    width: 100%;

    mat-icon {
      vertical-align: middle;
    }

    .close-chip {
      padding: 0px !important;
    }
  }
}

.k-notification-group {
  z-index: 999999999999999999 !important;
}

.mat-icon {
  overflow: inherit !important;
}

.dm-error-list {
  .k-grid td.k-selected,
  .k-grid tr.k-selected>td {
    background-color: unset !important;
  }
}

.dm-processed-list{
  .k-grid td.k-selected,
  .k-grid tr.k-selected>td {
    background-color: unset !important;
  }
}


.rowhideexpand{
  .k-hierarchy-cell{
      .k-i-plus{
          display: none !important;
      }

  }
}